<template>
    <!-- 注册账号 && 忘记密码 -->
    <div class="register flex alignCenter">
        <div class="registerContent centerWidth marginAuto flex flexColumn alignCenter">
            <table>
                <tr>
                    <td style="text-align:left;width:85px;" class="c33 ">手机号码</td>
                    <td style="width: 310px;">
                        <input v-model="userInfo.phone" type="text" placeholder="请填写手机号码">
                    </td>
                </tr>
                <tr>
                    <td style="text-align:left;width:85px;" class="c33 ">验证码</td>
                    <td style="width: 330px;height:80px;" class="flex spacebetween alignCenter">
                        <input type="text" v-model="userInfo.code" style="width:168px;" placeholder="请填写验证码">
                        <button @click="sendCode">{{getCode}}</button>
                    </td>
                </tr>
            </table>
            <button class="submit marginAuto" @click="nowRegister">立即绑定</button>
        </div>
    </div>
</template>
<script>
import { sms, phone_register, forget_password } from '@/utils/Api/doLogin'
import { pc_wxlogin_openid, tripartite_register } from '@/utils/Api/commonList'
import axios from 'axios';
export default {
    name: 'register',
    data(){
        return{
            registerType: 1,                            // 注册类型 1 个人 2 机构
            getCode: '获取验证码',
            times: 60,
            codeTimer: null,
            userInfo:{},
            btntext:'',
        }
    },
    created(){
        // this.getOpenid()
        console.log(11111111111)
        console.log(this.wechatInfo)
    },
    methods:{
        getOpenid(){
            pc_wxlogin_openid({wx_code: this.$route.query.code}).then(res => {
                console.log(res)
                this.wechatInfo = res
            })
        },
        async nowRegister(){
            if(!this.userInfo.phone){
                this.$message.warning('请填写手机号码')
                return
            }
            if(!(/^(1[0-9])\d{9}$/.test(this.userInfo.phone))){
                this.$message.warning('手机号填写不正确')
                return
            }
            if(!this.userInfo.code){
                this.$message.warning('请填写验证码')
                return
            }

            tripartite_register({
                code: this.userInfo.code,
                type: 3,
                openid: this.wechatInfo.openid,
                phone: this.userInfo.phone,
                portrait: this.wechatInfo.headimgurl,
                name: this.wechatInfo.nickname,
            }).then(res => {
                this.$store.dispatch('user/set_token', res.token)
                this.$message.success('手机号绑定成功！')
                setTimeout(() => {
                    this.goother({path:'/'})
                }, 1000);
            })
        },
        async sendCode(){
            if(!this.userInfo.phone){
                this.$message.warning('请填写手机号码')
                return
            }
            if(!(/^(1[0-9])\d{9}$/.test(this.userInfo.phone))){
                this.$message.warning('手机号填写不正确')
                return
            }
            if(this.codeTimer){
                this.$message.warning(`请在${this.times}后重新发送！`)
                return
            }
            try{
                await sms({phone: this.userInfo.phone})
                this.$message.success('验证码发送成功！')
            }catch(e){console.log(e)}

            this.codeTimer = setInterval(() => {
                this.times--
                this.getCode = `${this.times}后重新发送`
                if(this.times < 1){
                    clearInterval(this.codeTimer)
                    this.codeTimer = null
                    this.getCode = '获取验证码'
                    this.times = 60
                }
            },1000)
        }
    },
    computed:{
        wechatInfo(){
            return this.$store.getters.wechatInfo
        }
    }
}
</script>
<style lang="scss" scoped>
    .submit{
        width: 391px;
        height: 53px;
        opacity: 1;
        background: #47d7e3;
        border-radius: 1px;
        margin-top: 30px;
        font-size: 16px;
        color: #fff;
    }
    table{
        margin-top: 36px;
        tr{height: 80px;}
        td{
            font-size: 15px;
        }
        button{
            width: 133px;
            height: 53px;
            opacity: 1;
            background: #47d7e3;
            border-radius: 1px;
            color: #fff;
            font-size: 16px;
            margin-left: 6px;
        }
        input{
            color: #333;
            width: 307px;
            height: 53px;
            opacity: 1;
            border: 1px solid #c6c6c6;
            border-radius: 2px;
            padding-left: 17px;
            font-size: 15px;
        }
    }
    .register{
        width: 100%;
        height: 400px;
        background: #fafafa;
        overflow: hidden;
        .registerContent{
            margin-top: 28px;
            // margin-bottom: 46px;
            height: 594px;
            background: #fff;
            overflow: hidden;
            p{
                margin-top: 44px;
                font-size: 22px;
            }
            h6{
                font-size: 16px;
                margin-top: 26px;
                span{
                    cursor: pointer;
                }
            }
        }
    }
</style>